<template>
  <v-main v-if="lvl == 1">
    <v-container fluid>
      <v-card class="pa-3 ma-2" flat>
        <v-row dense>
          <v-col cols="12" >
            <div class="headline font-weight-bold pa-1 pb-0">푸시 메시지 보내기</div>
            <div>타입을 선택하면 푸시 메시지 클릭 시, 해당 페이지로 이동하게 됩니다.</div>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="types"
              item-text="name"
              item-value="value"
              v-model="selected_type"
              label="타입" />
          </v-col>
          <v-col cols="6" >
            <v-text-field
              v-model="msg"
              class="center-input"
              label="보낼 메시지"
              required
              hint=""
              persistent-hint
              v-on:change="changeMSG" />
          </v-col>
          <v-col cols="3">
            <v-btn large color="#FBDE44FF" class="elevation-0 op_btn" @click="sendMSG" >
              <v-icon color="black" medium>send</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

    </v-container>
  </v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex'


export default {
  props: {

  },
  data: () => {
    return {
      msg: "",
      selected_type: 1,
      types: [
        { name: '기본', value: 1 },
        { name: '식품추천', value: 2 },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",
    })
  },
  created: async function() {

  },
  mounted: async function() {

  },
  watch: {

  },
  methods: {
    changeMSG: function(msg) {
      this.msg = msg;
    },
    sendMSG: async function() {
      if(confirm("메시지를 보내겠습니까?")) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/message/', {
          email: this.user.data.email,
          message: this.msg+"#!"+this.selected_type,
        }, {
          headers: {'id_token': id_token},
        });
      }
    }
  }
}
</script>

<style scoped>

.desc {
  margin-top:10px;
}

.headline {
  font-size: 1.3rem !important;
}

.desc_title {
  font-size:1.1em;
}


</style>
